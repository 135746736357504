<template>
  <div class="user-box">
    <el-form :model="queryParams" ref="queryForm"  :inline="true" v-show="showSearch" label-width="0px">
      <el-form-item label="" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入姓名"
          clearable
          @keyup.enter.native="handleQuery"
        >
          <template slot="prepend">姓名</template>
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="idcard">
        <el-input
          v-model="queryParams.idcard"
          placeholder="请输入身份证号"
          clearable
          @keyup.enter.native="handleQuery"
        >
          <template slot="prepend">身份证号</template>
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="phone">
        <el-input
          v-model="queryParams.phone"
          placeholder="请输入手机号"
          clearable
          @keyup.enter.native="handleQuery"
        >
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search"  @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh"  @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8" style="margin-bottom: 20px">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"

          @click="handleAdd"
          v-hasPermi="['system:blackUser:add']"
        >新增</el-button>
      </el-col>
    </el-row>

    <el-table  border v-loading="loading" :data="blackUserList" @selection-change="handleSelectionChange">
      <el-table-column type="index" width="55" align="center" />
      <el-table-column label="姓名" align="center" prop="name" />
      <el-table-column label="身份证号" align="center" prop="idcard" />
      <el-table-column label="手机号" align="center" prop="phone" />
      <el-table-column label="类型" align="center" prop="type" >
        <template slot-scope="scope">
          <el-tag v-if="!scope.row.phone && scope.row.idcard">二要素</el-tag>
          <div v-else-if="!scope.row.idcard" type="danger">-</div>
          <el-tag v-else type="danger">三要素</el-tag>
        </template>
      </el-table-column>
<!--      <el-table-column label="操作人" align="center" prop="createNames" />-->
      <el-table-column label="备注" align="center" prop="memo" />
      <el-table-column label="拉黑时间" align="center" prop="createTime" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button

            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            :disabled="scope.row.creater != user.id"
          >移除</el-button>
        </template>
      </el-table-column>
    </el-table>
<!--    &lt;!&ndash; 分页管理 &ndash;&gt;-->
<!--    <el-pagination-->
<!--      background-->
<!--      :page-size="queryParams.pageSize"-->
<!--      layout="total, sizes, prev, pager, next"-->
<!--      :total="total"-->
<!--      @size-change="(res)=>{queryParams.pageSize=res;getList()}"-->
<!--      @current-change="(res)=>{queryParams.pageNum=res;getList()}"-->
<!--      :current-page="queryParams.pageNum"-->
<!--    ></el-pagination>-->

    <!-- 添加或修改黑名单对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="身份证号" prop="idcard">
          <el-input v-model="form.idcard" placeholder="请输入身份证号" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号" />
        </el-form-item>

        <el-form-item label="备注" prop="memo">
          <el-input v-model="form.memo" type="textarea" placeholder="请输入备注" />
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listBlackUser, getBlackUser, delBlackUser, addBlackUser, updateBlackUser } from "./blackUser";
import verify from '@/utils/verify';
export default {
  name: "BlackUser",
  data() {
    var checkIdCard = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入身份证号'));
      } else if (!verify.idCard(value)) {
        callback(new Error('请输入正确的身份证号'));
      }
      callback();
    };
    var checkmobile = (rule, value, callback) => {
      // console.log(value,this.search.factor)
      if (!value && this.search.factor == 2) {

        return callback(new Error('请输入手机号'));
      }

      var s = /^1(3|4|5|6|7|8|9)\d{9}$/.test(value);
      if (!s && this.search.factor == 2) {
        callback(new Error('请输入正确格式的手机号'));
      } else {
        callback();
      }

    };
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 黑名单表格数据
      blackUserList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        idcard: null,
        phone: null,
        type: null,
        creater: null,
        isDel: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        name: [
          { required: true, message: "姓名不能为空", trigger: "blur" }
        ],
        idcard: [{required: true, validator: checkIdCard, trigger: 'change' }]

      }
    };
  },
  computed: {
    user(){
      return this.$store.state.XINYOU_SH_USER || {};
    },
  },
  created() {
    // console.log(this.user.id)
    this.getList();
  },
  methods: {
    /** 查询黑名单列表 */
    getList(e) {
      // console.log(JSON.stringify(e))
      this.loading = true;
      listBlackUser(this.queryParams).then(response => {
        this.blackUserList = response.records;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        name: null,
        idcard: null,
        phone: null,
        type: null,
      };
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.name = undefined
      this.queryParams.idcard = undefined
      this.queryParams.phone = undefined
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加黑名单";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getBlackUser(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改黑名单";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateBlackUser(this.form).then(response => {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.open = false;
              this.getList();
            });
          } else {
            addBlackUser(this.form).then(response => {
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 移除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认移除黑名单编号为"' + row.name + '"的数据项？').then(function() {
        return delBlackUser(row.id+"");
      }).then(() => {
        this.getList();
        this.$message({
          message: '移除成功',
          type: 'success'
        });
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('system/blackUser/export', {
        ...this.queryParams
      }, `blackUser_${new Date().getTime()}.xlsx`)
    }
  }
};
</script>
