import API from "@/api";
// 查询黑名单列表
export function listBlackUser(query) {

  return API.requestGet({
    params:query,
    url: "system/blackUser/list",
    auth: true,
  },true);
}


// 新增黑名单
export function addBlackUser(data) {
  return API.requestPost({
    data:data,
    url: "system/blackUser/add",
    auth: true,
  },true);
}

// 新增黑名单
export function delBlackUser(data) {
  return API.requestGet({
    url: "system/blackUser/del?id="+data,
    auth: false,
  },true);
}
